/* eslint-disable react/iframe-missing-sandbox */
/* eslint-disable no-irregular-whitespace */
/* eslint-disable prettier/prettier */
/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable prefer-destructuring */
/* eslint-disable guard-for-in */
/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable no-duplicate-imports */
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState, useRef } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Box,
  Grid,
  Table,
  Select,
  Switch,
  colors,
  Dialog,
  Popover,
  TableRow,
  MenuItem,
  TableHead,
  TableCell,
  TableBody,
  TextField,
  InputLabel,
  Typography,
  IconButton,
  FormControl,
  Autocomplete,
  DialogActions,
  DialogContent,
  TableContainer,
  FormHelperText
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { LoadingButton } from '@mui/lab';
import { AtomicBlockUtils, ContentState, EditorState, Entity, Modifier, SelectionState, convertToRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import _ from 'lodash';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import '../../../../../../utils/richEditor.scss';
import { Controller, FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import * as yup from 'yup';
import { API } from '../../../../../../api/index';
import upload from '../../../../../../assets/images/upload.svg';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import { articleActions, fetchAllArticles } from '../../../../../../redux/slices/article/getAllArticleSlice';
import ArticleDeletDialog from '../ArticleDeletDialog';
import PreviewContent from '../PreviewContent';
import { getUserDetails } from '../../../../../../utils/orgName';
import { useAuth0 } from '@auth0/auth0-react';
// import ContentFilter from './ContentFilter';
import draftToHtml from 'draftjs-to-html';
import { Close } from '@mui/icons-material';
import htmlToDraft from 'html-to-draftjs';
import { FileOutlined } from '@ant-design/icons';
import Upvotes from '../../../../components/Upvotes';
import { FituredImage } from '../../../chat/ChatEditorComponent';
import CameraAltOutlinedIcon from '@mui/icons-material/CameraAltOutlined';
import { ChatStyle } from '../../../chat/ChatStyling';
import {
  GroupBasedRoleFormatter,
  handleImageCompression,
  imageSizeLimit,
  industryArray,
  roleNameFormatter,
  showMyLoader,
  topicArray,
  updateInsightDataByArticleId
} from '../../../../components/common';
import CommonModalHeader from '../../../../../../components/DialogUi/CommonModalHeader';
import MyChip from '../../../../components/MyChip';
import { is } from 'immer/dist/internal';
import { DeleteIcon, EditIcon, FilterIcon, PlusCircleIcon, PreviewIcon } from '../../../../../../utils/Icons';
import { useLoader } from '../../../../components/useLoader';
import { ContentSettingsLoader } from '../../../../../../Loader/ContentSettingsLoader';
import { reactDraftWysiwygToolbarOptionsarticle } from '../../../../../../utils/react-draft-wysiwyg-options';
import { ImageBlock } from './settings components/TaskPreviewRightBar';
const imgSize = imageSizeLimit();
const acceptedImages = 'image/jpeg,image/jpg,image/png';

const IframeBlock = ({ block, blockProps }: { block: any; blockProps: any }) => {
  const entityKey = block.getEntityAt(0);
  const { setEditorState } = blockProps.blockProps;

  const removeBlock = () => {
    const { editorState,descriptionkey } = blockProps.blockProps;
    const selectionState = editorState.getSelection();
    const blockKey = block.getKey();
    const blockSelection = selectionState.merge({
      anchorKey: blockKey,
      focusKey: blockKey,
      anchorOffset: 0,
      focusOffset: block.getLength()
    });

    const updatedContentState = Modifier.removeRange(editorState.getCurrentContent(), blockSelection, 'backward');
    const newEditorState = EditorState.push(editorState, updatedContentState, 'remove-range');

    setEditorState(newEditorState);
  };

  const contentState = blockProps.editorState.getCurrentContent();

  return (
    <div className="iframe-block">
      {entityKey !== null ? (
        <>
          <iframe src={contentState.getEntity(entityKey).getData().src} title="Embedded Content" />
          <button onClick={removeBlock}>X</button>
        </>
      ) : null}
    </div>
  );
};

interface Props { }

export interface Article {
  articleId: string;
  orgId: string;
  groups: string[];
  articleName: string;
  type: string;
  coverImageUrl: string;
  fileUrl: string;
  authorName: string;
  authorId: string;
  createdBy?: string;
  readTime: string;
  accessFor: string;
  articleViewableTo: string;
  articleViewableUserType: string[];
  tags: string[];
  industries: string[];
  articleDescription: string;
  embeddedLinks: string;
  approved: boolean;
  upVotes: number;
  downVotes: number;
  views: number;
  isPublished: boolean;
  allowedGroup: boolean;
  __v: number;
  createdAt: number;
  location?: string;
}

const schema = yup.object().shape({
  articleName: yup.string().required('Article name is required'),
  type: yup.string().required('Article type is required'),
  readTime: yup.string().required('Read time is required'),
  authorName: yup.string().required('Author Name is required'),
  // accessFor: yup.string().required('Who Can View is required'),
  articleViewableTo: yup.string().required('Who can view is required'),
  articleViewableUserType: yup.array().required('Required').min(1, 'Select at least one Member'),
  tags: yup.array().min(1, 'Select at least one tag').required('Select at least one tag'),
  articleDescription: yup.string().required('Article Description is required'),
  coverImg: yup.mixed().required('Cover image is required'),
  industries: yup.array().min(1, 'Select at least one Industry').required('industry in required')
});

const ContentSettings = (props: Props) => {
  const dispatch = useAppDispatch();
  const params = useParams();
  const grpId = params.id || '';
  const orgId = params.orgId || '';
  const [openPreview, setOpenPreview] = useState(false);
  const [singleListData, setSingleListData] = useState<Article>();
  const [openDelete, setDelete] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [articleId, setArticleId] = useState<string>('');
  const [selectedLocation, setLocation] = useState<string>('');
  const [articleAccessFor, setArticleAccessFor] = useState<boolean>(false);
  const [openForm, setOpenForm] = useState(false);
  const [action, setAction] = useState('Add');
  const [isPublished, setIsPublished] = useState(true); // default set to true
  const [editCoverImg, setEditCoverImg] = useState<string>();
  const [type, setType] = useState('');
  const [readTimeValue, setReadTimeValue] = useState('');
  const [coverImageUrl, setCoverImageUrl] = useState<File | null>(null);
  const [viewFor, setViewFor] = useState('');
  const [addArticlesLoader, setAddArticlesLoaer] = useState(false);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [videoFile, setVideoFile] = useState<File | null>(null);
  const [videoError, setVideoError] = useState<string | null>(null);
  const [editVideoUrl, setEditVideoUrl] = useState<File | null>(null);
  const [audioFile, setAudioFile] = useState<any>(null);
  const [textFile, setTextFile] = useState<any>(null);
  const [defaultAudioUrl, setDefaultAudioUrl] = useState<any>('');
  const [defaultVideoUrl, setDefaultVideoUrl] = useState<any>('');
  const [audioError, setAudioError] = useState<string | null>(null);
  const [textFileError, setTextFileError] = useState<string | null>(null);
  const [editAudioUrl, setEditAudioUrl] = useState<File | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  // const [error, setError] = useState('');
  const [imgError, setImgError] = useState('');
  const loading: boolean = useAppSelector((state) => state?.allArticle?.loading);
  const showLoader = showMyLoader();
  const [tagList, setTagList] = useState<string[]>([]);
  const groupData = useAppSelector((state) => state.groupDetails);
  const groupTopicsData = groupData?.data?.topics || [];
  const groupIndustryData = groupData?.data?.industries || [];
  const topics = topicArray();
  const industries = industryArray();

  useEffect(() => {
    localStorage.removeItem('deletedFiles'); 
  }, []);

  const camelCaseToCapital = (topicsArray: string[]) => {
    return topicsArray.map((tag: string) => {
      const index = topics.findIndex((each) => each.name === tag);
      if (index !== -1) {
        return topics[index].label;
      } else {
        return tag;
      }
    });
  };

  const camelCaseToCapitalForIndustry = (industryArray: string[]) => {
    return industryArray.map((tag: string) => {
      const index = industries.findIndex((each) => each.name === tag);
      if (index !== -1) {
        return industries[index].label;
      } else {
        return tag;
      }
    });
  };

  const topicsList: string[] = camelCaseToCapital(groupTopicsData);
  const industryList: string[] = camelCaseToCapitalForIndustry(groupIndustryData);
  const [industryTags, setIndustryTags] = useState<string[]>([]);
  const { user } = useAuth0();
  const { location, orgId: userOrgId } = getUserDetails();
  const classes = ChatStyle();
  const [audioUrl, setAudioUrl] = useState<any>(null);
  const [textFileUrl, setTextFileUrl] = useState<any>(null);
  const [videoUrl, setVideoUrl] = useState<any>(null);
  const [videoSource, setVideoSource] = useState<any>(null);
  const [editEmbedded, setEmbedded] = useState<boolean>(false);
  const blobTextFileUrl = textFile ? URL.createObjectURL(textFile) : null;
  const roleOptions = ['mentee', 'mentor', 'admin', 'All members'];
  const mentorRoleText = GroupBasedRoleFormatter('mentor', 'Mentor');
  const menteeRoleText = GroupBasedRoleFormatter('mentee', 'Mentee');
  const [userTypeError, setUserTypeError] = useState(false);
  const [userTypes, setUserTypes] = useState<string[]>([]);
  // const [openFilter, setopenFilter] = useState(false);
  // const handleopenFilter = (flag: boolean) => setopenFilter(flag);

  useEffect(() => {
    Promise.all([
      fetchTagList('FYE'),
      fetchTagList('Careers'),
      fetchTagList('Consulting'),
      fetchTagList('industry')
    ]).then(([tagList1, tagList2, tagList3, tagList4]) => {
      setTagList(tagList1.concat(tagList2).concat(tagList3));
      setIndustryTags(tagList4);
    });
  }, []);

  const fetchTagList = async (type: string) => {
    try {
      const responseFYE = await API.getTagsListnew(userOrgId, location, type, '', true);
      if (responseFYE.status === 200) {
        return responseFYE?.data?.tagListResponse.map((each: any) => each.value) || [];
      } else {
        return [];
      }
    } catch {
      return [];
    }
  };

  const [articleData, setArticleData] = useState<{ articleList: Article[] }>({
    articleList: []
  });

  const allArticleData = useAppSelector((state) => state.allArticle.data);
  let file = null;
  let fileSize = 0;

  useEffect(() => {
    if (allArticleData && allArticleData.ArticleList) {
      setArticleData((prevArticleData) => ({
        ...prevArticleData,
        articleList: allArticleData.ArticleList
      }));
    }
  }, [allArticleData]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  useEffect(() => {
    setType('');
    setReadTimeValue('');
    setViewFor('');
  }, []);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    clearErrors,
    reset,
    watch,
    setError
  } = useForm({
    resolver: yupResolver(schema)
  });

  const watchArticleType = watch('type');
  const TagList = ['Company', 'Industry', 'Department', 'Degree', 'Minor', 'Role'];
  const checkError = (fieldName: string) => Boolean(errors[fieldName]);
  const getError = (fieldName: string) => errors[fieldName]?.message;
  const [urlName, setUrlName] = useState('');

  let filename = urlName.substring(urlName.lastIndexOf('/') + 1);
  filename = filename.split('.')[0];
  filename = filename.replace(/^\d+_/, '');
  filename = filename.replace(/%20/g, ' ');
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      if (file.size > 100 * 1024 * 1024) {
        setVideoError('File size is too large. Maximum allowed size is 100MB.');
      } else {
        setVideoFile(file);
        setVideoError(null);
      }
    } else {
      setVideoFile(null);
      setVideoError('Video file is required for Video type.');
    }
  };
  const handleAudioFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files && e.target.files[0];
    if (file) {
      if (file.size > 100 * 1024 * 1024) {
        setAudioError('File size is too large. Maximum allowed size is 100MB.');
      } else {
        setAudioFile(file);
        setAudioError(null);
      }
    } else {
      setAudioFile(null);
      setAudioError('Audio file is required for Audio type.');
    }
  };

  const handleTextFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const targetFile: any = e.target.files && e.target.files[0];

    if (targetFile) {
      const fileExtension = targetFile.name.split('.').pop(); // Extract the file extension

      const file = new File(
        [targetFile],
        watch('articleName') ? watch('articleName') + `.${fileExtension}` : `attachment.${fileExtension}`,
        {
          type: targetFile.type
        }
      );

      if (file) {
        if (file.size > 100 * 1024 * 1024) {
          setTextFileError('File size is too large. Maximum allowed size is 100MB.');
        } else {
          if (fileExtension === 'pdf' || fileExtension === 'csv') {
            setTextFile(file);
          } else {
            setTextFileError('Unsupported file type. Please upload a PDF or CSV file.');
          }

          setTextFileError(null);
        }
      } else {
        setTextFile(null);
        setTextFileError('Audio file is required for Audio type.');
      }
    } else {
      setTextFile(null);
      setTextFileError('No file selected.');
    }
  };

  const validateAudioUrl = (url: string) => {
    const audioExtensions = ['.mp3', '.wav', '.ogg'];
    const isValidAudioUrl = audioExtensions.some((extension) => url.endsWith(extension));
    return isValidAudioUrl;
  };

  const [youtubeUrl, setYoutubeUrl] = useState<boolean>();

  const validateVideoUrl = (url: string) => {
    // const regularUrlRegex = /^(https?:\/\/)?(www\.)?youtube\.com\/watch\?v=[\w-]{11}$/;
    const regularUrlRegex = /^(https?:\/\/)?(www\.)?youtube\.com\/.*[?&]v=[\w-]{11}$/;

    // Regular expression for a youtu.be URL
    const shortUrlRegex = /^(https?:\/\/)?(www\.)?youtu\.be\/[\w-]{11}(\?[\w=&-]+)?$/;

    const reg = regularUrlRegex.test(url);
    const short = shortUrlRegex.test(url);

    const regAndShort = regularUrlRegex.test(url) || shortUrlRegex.test(url);

    setYoutubeUrl(regAndShort);

    const videoExtensions = ['.mp4', '.MOV', '.WMV', '.WEBM', '.webm'];
    const isValidVideoUrl = videoExtensions.some((extension) => url.endsWith(extension));

    return isValidVideoUrl || regAndShort;
  };

  useEffect(() => {
    const delay = 500;
    const timeoutId = setTimeout(() => {
      if (defaultAudioUrl) {
        if (validateAudioUrl(defaultAudioUrl)) {
          setAudioUrl(defaultAudioUrl);
          // setAudioUrlError(null);
        } else {
          // setAudioUrlError('Invalid audio URL');
        }
      } else {
        setAudioUrl('');
        // setAudioUrlError(null);
      }
    }, delay);

    return () => clearTimeout(timeoutId);
  }, [defaultAudioUrl]);

  const [youtubeVideoUrlTest, setYoutubeVideoUrlTest] = useState('');

  useEffect(() => {
    const delay = 100;
    const timeoutId = setTimeout(() => {
      if (defaultVideoUrl) {
        if (validateVideoUrl(defaultVideoUrl)) {
          setVideoUrl(defaultVideoUrl);
          setYoutubeVideoUrlTest(defaultVideoUrl);
          // setAudioUrlError(null);
        } else {
          // setAudioUrlError('Invalid audio URL');
        }
      } else {
        setVideoUrl('');
        // setAudioUrlError(null);
      }
    }, delay);

    return () => clearTimeout(timeoutId);
  }, [defaultVideoUrl]);

  const handleAudioUrlChange = (e: ChangeEvent<HTMLInputElement>) => {
    const url = e.target.value;
    setDefaultAudioUrl(url);
  };

  // const handleVideoUrlChange = (e: ChangeEvent<HTMLInputElement>) => {
  //   const url = e.target.value;
  //   const videoId = url.match(/youtu\.be\/([^?]+)/);
  //     if (videoId) {
  //       // Construct the new embed URL for youtu.be format
  //       setDefaultVideoUrl(`https://www.youtube.com/embed/${videoId[1]}`);
  //       setVideoUrl(`https://www.youtube.com/embed/${videoId[1]}`)
  //       return
  //     }
  //   setDefaultVideoUrl(url);
  // };

  const handleVideoUrlChange = (e: ChangeEvent<HTMLInputElement>) => {
    const url = e.target.value;

    // Check if it's a youtu.be URL
    const youtuBeMatch = url.match(/youtu\.be\/([^?]+)/);
    if (youtuBeMatch) {
      const videoId = youtuBeMatch[1];
      // Construct the embed URL for youtu.be format
      setDefaultVideoUrl(`https://www.youtube.com/embed/${videoId}`);
      setVideoUrl(`https://www.youtube.com/embed/${videoId}`);
      return;
    }

    // Check if it's a regular YouTube URL
    const regularUrlMatch = url.match(/[?&]v=([^&]+)/);
    if (regularUrlMatch) {
      const videoId = regularUrlMatch[1];
      // Construct the embed URL for regular YouTube URL
      setDefaultVideoUrl(`https://www.youtube.com/embed/${videoId}`);
      setVideoUrl(`https://www.youtube.com/embed/${videoId}`);
      return;
    }

    // If it's neither youtu.be nor regular YouTube URL, set the default video URL
    setDefaultVideoUrl(url);
  };

  const handelDeleteArticle = async () => {
    setDeleteLoader(true);
    setAnchorEl(null);
    try {
      const response = await API.DeleteArticle({
        orgId: orgId,
        articleId: articleId,
        groupId: grpId
      });

      if (response.status === 200) {
        toast.success('Article deleted successfully');
        setDeleteLoader(false);
        setDelete(false);
        dispatch(articleActions.deleteArticle(articleId));
      } else {
        toast.error('Something went wrong, please try again');
        setDeleteLoader(false);
      }
    } catch (error) {
      // console.error('Failed to delete article', error);
      toast.error('Something went wrong, please try again');
    }
  };

  const insertImage = (editorState: EditorState, imageUrl: string, altImage: string) => {
    const contentState = editorState.getCurrentContent();
    const contentStateWithEntity = contentState.createEntity('IMAGE', 'IMMUTABLE', { src: imageUrl, alt: altImage });
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey();

    const newEditorState = AtomicBlockUtils.insertAtomicBlock(editorState, entityKey, ' ');

    return newEditorState;
  };

  const handlePaste = useCallback(
    (event: ClipboardEvent) => {
      const items = event.clipboardData?.items;
      if (!items) return;

      for (const item of items) {
        if (item.type.indexOf('image') === 0) {
          const file = item.getAsFile();
          if (file) {
            imageUpload(file, 400, 400, 0.5)
              .then((response: any) => {
                if (response.data.link) {
                  const imageUrl = response.data.link;
                  const altImage = 'image';
                  const width = '400px';
                  // Insert the uploaded image into the editor
                  const newEditorState = insertImage(editorState, imageUrl, altImage);
                  setEditorState(newEditorState);
                  // Prevent the default paste behavior
                  event.preventDefault();
                  return;
                }
              })
              .catch((error: any) => {
                console.error('Image upload error:', error);
              });
          }
        }
      }
    },
    [editorState]
  );

  useEffect(() => {
    const editorContainer = document.querySelector('.editorSize');
    if (editorContainer) {
      editorContainer.addEventListener('paste', handlePaste as any);
    }

    return () => {
      if (editorContainer) {
        editorContainer.removeEventListener('paste', handlePaste as any);
      }
    };
  }, [handlePaste]);

  const handelOnClickPreviewArticleName = async (articleId: string, location: any) => {
    setArticleId(articleId);
    try {
      const response = await API.getAllArticleById({
        orgId: orgId,
        articleId: articleId,
        groupId: grpId,
        isContentSettings: true,
        articleLocation: location ? location : ''
      });

      if (response && response.status === 200) {
        setSingleListData(response.data.article);
        setOpenPreview(true);
      }
    } catch (error) {
      // console.error(error);
    }
    setAnchorEl(null);
    updateInsightDataByArticleId(orgId, articleId, location);
  };

  const blockRender = (contentBlock: any, descriptionkey: string) => {
    const entityKey = contentBlock.getEntityAt(0);
    const contentState = editorState.getCurrentContent();

    if (contentBlock.getType() === 'atomic' || contentBlock.text === '📷') {
      return {
        component: ImageBlock,
        editable: false,
        props: {
          block: contentBlock,
          // contentState,
          blockProps: {
            editorState,
            setEditorState,
            descriptionkey: descriptionkey
          }
        }
      };
    }
    return null;
  };

  const findImageEntities = (contentBlock: any, callback: any, contentState: any) => {
    contentBlock.findEntityRanges((character: any) => {
      const entityKey = character.getEntity();
      return entityKey !== null && contentState.getEntity(entityKey).getType() === 'IMAGE';
    }, callback);
  };

  const decorator = [{ strategy: findImageEntities, component: FituredImage }];

  const handleOpendelete = () => {
    setAnchorEl(null);
    setDelete(true);
  };

  const handleClickPopover = (
    event: React.MouseEvent<HTMLButtonElement>,
    articleId: string,
    accessFor: boolean,
    location: string
  ) => {
    setLocation(location);
    setArticleId(articleId);
    setAnchorEl(event.currentTarget);
    setArticleAccessFor(accessFor);
  };

  const handelOnClickPreview = async (location: any) => {
    try {
      const response = await API.getAllArticleById({
        orgId: orgId,
        articleId: articleId,
        groupId: grpId,
        isContentSettings: true,
        articleLocation: location ? location : ''
      });

      if (response && response.status === 200) {
        setSingleListData(response.data.article);
      }
    } catch (error) {
      // console.error(error);
    }
    setAnchorEl(null);
    setOpenPreview(true);
    updateInsightDataByArticleId(orgId, articleId, location);
  };

  const handleClosePopover = () => {
    setAnchorEl(null);
  };

  const handleClickOpen = () => {
    setImgError('');
    setAction('Add');
    setOpenForm(true);
    setVideoFile(null);
    reset();
    setCoverImageUrl(null);
    setEditCoverImg('');
    setEditorState(EditorState.createWithContent(ContentState.createFromText('')));
    setEditVideoUrl(null);
    setAudioFile(null);
    setEditAudioUrl(null);
    setTextFile(null);
    setTextFileUrl(null);
    setAudioError('');
    setVideoError('');
    // setImgError('');
  };

  const handleSwitchSecondChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    setIsPublished(checked);
  };

  const handleSwitchChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
    articleId: string,
    location: string,
    accessArticle: boolean
  ) => {
    setLocation(location);
    let coverImg = '';
    let file = '';
    let type = '';
    const updatedArticleList = articleData.articleList.map((article) => {
      if (article.articleId === articleId) {
        // coverImg = article.coverImageUrl;
        // file = article.fileUrl;
        // type = article.type;
        return {
          ...article,
          // isPublished: checked,
          allowedGroup: checked
        };
      }
      return article;
    });
    setArticleData((prevArticleData) => ({
      ...prevArticleData,
      articleList: updatedArticleList
    }));

    const formData = new FormData();
    formData.append('toggleChange', 'true');
    formData.append('allowedGroup', checked.toString());
    if (accessArticle) {
      // isPublished will have the same value of allowedGroup in case of own article
      formData.append('isPublished', checked.toString());
    } else {
      formData.append('isPublished', 'true');
    }
    // formData.append('coverImage', coverImg);
    // if (file) {
    //   formData.append('type', type);
    //   formData.append('file', file);
    // }

    try {
      const response = await API.editArticle({
        articleId: articleId,
        data: formData,
        orgId: orgId,
        groupId: grpId,
        helpSettings: false,
        location: location
      });

      if (response.status === 200) {
        dispatch(articleActions.updateArticle(response.data.updatedArticle));
        // Handle success
        //toast.success('Group allowed status updated successfully');
      } else {
        // Handle failure
        toast.error('Group allowed status failed to update');
      }
    } catch (error) {
      toast.error('Something went wrong, please try again');
    }
  };

  const handleCloseArticleForm = () => {
    setOpenForm(false);
    reset();
    setCoverImageUrl(null);
    setVideoFile(null);
    setEditAudioUrl(null);
    setAudioUrl(null);
    setDefaultAudioUrl(null);
    setDefaultVideoUrl(null);
    setVideoUrl(null);
    setEditCoverImg('');
    setEmbedded(false);
    setTextFile(null);
    setTextFileUrl(null);
    setImgError('');
  };

  const handleEditorStateChange = (editorState: EditorState) => {
    setEditorState(editorState);
    let contentState = editorState.getCurrentContent();
    contentState.getBlockMap().forEach((block) => {
      if (!block) return;
      block.findEntityRanges(
        (character) => {
          const entityKey = character.getEntity();
          if (entityKey !== null) {
            const entity = contentState.getEntity(entityKey);
            return entity.getType() === 'IMAGE';
          }
          return false;
        },
        (start, end) => {
          const entityKey = block.getEntityAt(start);
          if (entityKey) {
            const entity = contentState.getEntity(entityKey);
            const entityData = entity.getData();
            const width = entityData.width?.toString().endsWith('px') ? entityData.width : `${entityData.width}px`;
            const height = entityData.height?.toString().endsWith('px') ? entityData.height : `${entityData.height}px`;
            contentState = contentState.replaceEntityData(entityKey, {
              ...entityData,
              width,
              height,
              style: `width: ${width}; height: ${height}; margin: 20px auto; display: block;`
            });
          }
        }
      );
    });
    const htmlContent = draftToHtml(convertToRaw(contentState));
    console.log(htmlContent, "content with dimensions in px");
    setValue('articleDescription', htmlContent);
  };

  const embedCallback = (url: any) => {
    if (url.indexOf('youtu.be/') >= 0) {
      // Extract the video ID from the URL
      const videoId = url.match(/youtu\.be\/([^?]+)/);
      if (videoId) {
        // Construct the new embed URL for youtu.be format
        return `https://www.youtube.com/embed/${videoId[1]}`;
      }
    } else if (url.indexOf('youtube') >= 0) {
      // Convert the 'watch' URL to the 'embed' format for youtube.com
      url = url.replace('watch?v=', 'embed/');
      url = url.replace('/watch/', '/embed/');
      url = url.replace('youtu.be/', 'youtube.com/embed/');
    }
    // If the URL doesn't match either format, return the original URL
    return url;
  };
  const handlePhotoChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setImgError('');
    const photo = event.target.files ? event.target.files[0] : null;
    if (!photo) {
      setCoverImageUrl(null);
      setImgError('Cover image is required');
      return;
    }
    if (photo && photo.size > imgSize.limitValue) {
      setImgError('We cannot upload this file, please try another.');
      return;
    }
    if (photo) {
      const fileType = photo.type.toLowerCase();
      if (fileType === 'image/jpeg' || fileType === 'image/jpg' || fileType === 'image/png') {
        handleImageCompression(photo)
          .then((compressedImage) => {
            if (compressedImage) {
              setCoverImageUrl(compressedImage);
            }
          })
          .catch((error) => {
            // console.error("Error", error);
          });
      } else {
        setImgError('Only JPG, JPEG and PNG files are allowed for the cover image.');
        return;
      }
    }
  };

  useMemo(() => {
    if (videoFile?.type?.startsWith('video/')) {
      setVideoSource(URL.createObjectURL(videoFile));
    } else if (defaultVideoUrl) {
      setVideoSource(defaultVideoUrl);
    } else {
      setVideoSource(videoUrl);
    }
  }, [videoFile, videoUrl, defaultVideoUrl]);

  useEffect(() => {
    if (watchArticleType === 'Text') {
      setAudioFile(null);
      setVideoFile(null);
      setAudioUrl(null);
      setVideoUrl(null);
      setDefaultAudioUrl(null);
      setDefaultVideoUrl(null);
    } else if (watchArticleType === 'Audio') {
      setVideoUrl(null);
      setVideoFile(null);
      setDefaultVideoUrl(null);
      setTextFileUrl(null);
      setTextFile(null);
    } else if (watchArticleType === 'Video') {
      setAudioUrl(null);
      setAudioFile(null);
      setDefaultAudioUrl(null);
      setTextFileUrl(null);
      setTextFile(null);
    }
  });

  // useEffect(() => {
  //   if ( !audioFile) {

  //     setAudioFile(audioVideoData);
  //   }
  // })

  // const cleanHtmlString = (htmlString: any) => {
  //   // Remove empty <figure> tags
  //   const cleanedHtml = htmlString?.replace(/<figure><img[^>]*><\/figure>/g, '');
  //   const withoutNbsp = cleanedHtml?.replace(/<figure>&nbsp;<\/figure>/g, '');
  //   // Remove <p> tags
  //   const strippedText = withoutNbsp?.replace(/<\/?p[^>]*>|<br\s*\/?>/g, '');
  //   return strippedText;
  // };

  const cleanHtmlString = (htmlString: any) => {
    // Remove empty <figure> tags
    const cleanedHtml = htmlString.replace(/<figure>&nbsp;<\/figure>/g, '');

    // Remove <p> tags
    const strippedText = cleanedHtml.replace(/<\/?p[^>]*>|<br\s*\/?>/g, '');

    return strippedText;
  };

  const handelEditArticle = async (location: any) => {
    setAction('Edit');
    setAudioError('');
    setVideoError('');
    setImgError('');
    setAnchorEl(null);
    setVideoFile(null);
    try {
      const response = await API.getAllArticleById({
        orgId: orgId,
        articleId: articleId,
        groupId: grpId,
        isContentSettings: true,
        articleLocation: location ? location : ''
      });
      if (response && response.status === 200) {
        let data = response?.data?.article;
        for (const field in data) {
          if (field === 'tags') {
            setValue(field, camelCaseToCapital(data[field]));
          } else if (field === 'industries') {
            setValue(field, camelCaseToCapitalForIndustry(data[field]));
          } else {
            setValue(field, data[field]);
          }
        }
        const {
          articleDescription,
          isPublished,
          coverImageUrl,
          fileUrl,
          embeddedLinks,
          articleViewableTo,
          articleViewableUserType
        } = data;
        // setAudioVideoData(data.fileUrl);

        if (data.fileUrl.endsWith('.mp3')) {
          setAudioUrl(data.fileUrl);
        } else if (data.fileUrl.endsWith('.mp4')) {
          setVideoUrl(data.fileUrl);
        } else if (fileUrl.startsWith('https://www.youtube.com/embed/')) {
          setVideoUrl(data.fileUrl);
        } else if (data.fileUrl.endsWith('.webm || WEBM')) {
          setVideoUrl(data.fileUrl);
        } else {
          setTextFileUrl(data.fileUrl);
        }

        setUrlName(fileUrl);
        if (/\.(mp4|webm|ogg|ogv)$/i.test(fileUrl)) {
          if (watchArticleType === 'Video') {
            setVideoFile(fileUrl);
          }
          setEditVideoUrl(fileUrl);
        }
        if (/\.(mp3|wav|ogg)$/i.test(fileUrl)) {
          if (watchArticleType === 'Audio') {
            setAudioFile(fileUrl);
          }
          setEditAudioUrl(fileUrl);
        }
        setValue('articleDescription', articleDescription);
        setValue('articleViewableUserType ', articleViewableUserType);
        setValue('articleViewableTo ', articleViewableTo);

        setValue('coverImg', coverImageUrl);
        setIsPublished(isPublished);
        setEditCoverImg(coverImageUrl);
        setEmbedded(true);
        const { contentBlocks, entityMap } = htmlToDraft(articleDescription);
        const customEntityMap = { ...entityMap };

        const contentStateWithEntities = ContentState.createFromBlockArray(contentBlocks, entityMap);

        const editorState = EditorState.createWithContent(contentStateWithEntities);
        setEditorState(editorState);
      }
    } catch (error) { }
    setOpenForm(true);
    updateInsightDataByArticleId(orgId, articleId, location);
  };

  const handleRemoveImageFile = () => {
    setCoverImageUrl(null);
    const inputElement = document.getElementById('coverImgInput') as HTMLInputElement;
    if (inputElement) {
      inputElement.value = '';
    }
    setEditCoverImg('');
  };
  const handleRemoveAudioFile = () => {
    setAudioFile(null);
    setEditAudioUrl(null);
    setAudioUrl(null);
    setDefaultAudioUrl(null);
  };
  const handleRemoveVideoFile = () => {
    setVideoFile(null);
    setEditVideoUrl(null);
    setVideoSource(null);
    setVideoUrl(null);
    setDefaultVideoUrl(null);
  };

  const [fileRemove, setFileRemove] = useState(false);
  const handleRemoveTextFileUrl = () => {
    setTextFile(null);
    setTextFileUrl(null);
    setFileRemove(true);
  };

  const validExtensions = ['.jpeg', '.png', '.jpg']; // for cover Image

  function getValidExtension(coverImageUrl: string) {
    const extension = validExtensions.find((extension) => coverImageUrl.endsWith(extension));
    return extension ? extension : '';
  }

  const onSubmit: SubmitHandler<FieldValues> = async (value) => {
    setAddArticlesLoaer(true);

    if (watchArticleType === 'Audio') {
      if (!audioFile && !audioUrl && !['mp3'].includes(defaultAudioUrl)) {
        setAudioError('Audio file is required for Audio type.');
        setAddArticlesLoaer(false);
        return;
      }
    }

    if (watchArticleType === 'Video') {
      if (!videoFile && !videoUrl && !videoSource && !['mp4'].includes(defaultVideoUrl)) {
        setVideoError('Video file is required for Video type.');
        setAddArticlesLoaer(false);
        return;
      }
    }

    if (!coverImageUrl && !editCoverImg) {
      setImgError('Cover image is required');
      setAddArticlesLoaer(false);
      return;
    }

    const formData = new FormData();
    formData.append('articleName', value.articleName);
    formData.append('type', value.type);
    formData.append('authorName', value.authorName);

    if (coverImageUrl) {
      formData.append('coverImage', coverImageUrl);
    }
    if (editCoverImg) {
      formData.append('coverImage', editCoverImg);
    }
    formData.append('readTime', value.readTime);
    // formData.append('accessFor', value.accessFor);
    formData.append('articleViewableTo', value.articleViewableTo);
    formData.append(
      'articleViewableUserType',
      value.articleViewableUserType[0] === 'All members' ? ['All'] : value.articleViewableUserType
    );
    formData.append(
      'tags',
      value.tags.map((each: any) => {
        const value = topics.find((tag) => tag.label === each);
        return value?.name;
      })
    );
    formData.append(
      'industries',
      value.industries.map((each: any) => {
        const value = industries.find((tag) => tag.label === each);
        return value?.name;
      })
    );
    if (value.embeddedLinks) {
      formData.append('embeddedLinks', value.embeddedLinks);
    }
    // formData.append('embeddedLinks', value.embeddedLinks)
    if (value.articleDescription !== '<p>/<p>') {
      formData.append('articleDescription', value.articleDescription);
      setError('articleDescription', { message: undefined });
    } else {
      setError('articleDescription', { message: 'Article description is required' });
    }
    formData.append('isPublished', isPublished.toString());
    if (watchArticleType === 'Text') {
      if (!textFile && !fileRemove) {
        if (['application/pdf', 'text/csv'].includes(value.fileUrl)) {
          formData.append('file', value.fileUrl);
        }
      }
    }

    if (['Add', 'Edit'].includes(action)) {
      if (watchArticleType === 'Video') {
        if (videoFile?.type?.startsWith('video/')) {
          formData.append('file', videoFile);
        } else if (defaultVideoUrl) {
          formData.append('file', defaultVideoUrl);
        } else if (value.fileUrl) {
          formData.append('file', value.fileUrl);
        }
      }

      if (watchArticleType === 'Audio') {
        if (audioFile?.type?.startsWith('audio/')) {
          formData.append('file', audioFile);
        } else if (defaultAudioUrl) {
          formData.append('file', defaultAudioUrl);
        } else if (value.fileUrl) {
          formData.append('file', value.fileUrl);
        }
      }

      if (watchArticleType === 'Text') {
        if (['application/pdf', 'text/csv'].includes(textFile?.type)) {
          formData.append('file', textFile);
        }
      }
    }

    if (action === 'Add') {
      try {
        const response = await API.createArticle({
          data: formData,
          orgId: orgId,
          groupId: grpId,
          helpSettings: false
        });
    
        if (response.status === 200 && response.data.message === 'Article Created Successfully') {
          setAddArticlesLoaer(false);
          dispatch(articleActions.addArticle(response.data.createArticleResponse));
    
          if (orgId && grpId) {
            dispatch(fetchAllArticles({ orgId, groupId: grpId, helpSettings: false, useCache: false }));
          }
    
          toast.success('Article created successfully');
          setOpenForm(false);
          setCoverImageUrl(null);
          reset();
          await handleFileDeletion();
    
        } else if (
          response.status === 200 &&
          response.data.message === "You don't have permission to access this API"
        ) {
          toast.error("You don't have permission to add an article");
          reset();
          setOpenForm(false);
        } else {
          throw new Error('Failed to create article');
        }
      } catch (error) {
        setAddArticlesLoaer(false);
        toast.error('Something went wrong, please try again');
      }
    }
    
    if (action === 'Edit') {
      try {
        formData.append('toggleChange', 'false');
        const response = await API.editArticle({
          data: formData,
          orgId: orgId,
          articleId: articleId,
          groupId: grpId,
          helpSettings: false,
          location: selectedLocation
        });
    
        if (response.status === 200) {
          setAddArticlesLoaer(false);
          dispatch(articleActions.updateArticle(response.data.updatedArticle));
    
          if (orgId && grpId) {
            dispatch(fetchAllArticles({ orgId, groupId: grpId, helpSettings: false, useCache: false }));
          }
    
          toast.success('Article updated successfully');
          setOpenForm(false);
          setCoverImageUrl(null);
          reset();
          await handleFileDeletion();
        }
      } catch (error) {
        setAddArticlesLoaer(false);
        setOpenForm(false);
        toast.error('Something went wrong, please try again');
      }
    }
    async function handleFileDeletion() {
      const deletedFiles = JSON.parse(localStorage.getItem('deletedFiles') || '[]');
      console.log("Attempting to delete files:", deletedFiles);
    
      if (deletedFiles.length > 0) {
        try {
          await Promise.all(deletedFiles.map((file: any) => {
            console.log('Deleting file:', file.fileName);
            return API.deleteDescriptionFile(file.fileName, 'article', location);
          }));
          console.log('Files deleted successfully');
          localStorage.removeItem('deletedFiles');
        } catch (error) {
          console.error('Error deleting files:', error);
          toast.error('Failed to delete some files');
        }
      }
    }
    
    formData.append('file', textFile);
    reset();
  };

  useEffect(() => {
    if (orgId && grpId) {
      dispatch(fetchAllArticles({ orgId: orgId, groupId: grpId, helpSettings: false, useCache: false }));
    }
  }, [orgId, grpId, dispatch]);

  /** Display articles */
  const displayArticles = () => {
    if (articleData && articleData.articleList) {
      return (
        <TableBody>
          {articleData?.articleList.length > 0 ? (
            articleData?.articleList?.map((article: any) => {
              return (
                <TableRow key={article.articleId}>
                  <TableCell>
                    <Box display="flex" alignItems="center">
                      <Switch
                        checked={article?.allowedGroup}
                        onChange={(event) =>
                          handleSwitchChange(
                            event,
                            !article.allowedGroup,
                            article.articleId,
                            article.location,
                            article?.accessArticle
                          )
                        }
                      />
                      <img alt="contentimg" className="contentimg" src={article.coverImageUrl} />
                      <Box className="twoLineThenDots">
                        <Typography
                          color="#0071A9"
                          variant="subtitle1"
                          className="cursor-pointer"
                          onClick={() => handelOnClickPreviewArticleName(article.articleId, article?.location)}
                        >
                          {article.articleName}
                          {/* {article.articleName.split(' ').length > 3
                          ? article.articleName.split(' ').slice(0, 3).join(' ')
                          : article.articleName} */}
                        </Typography>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell>
                    <Upvotes likes={article?.upVotes} />
                  </TableCell>
                  <TableCell>
                    <Upvotes likes={article?.downVotes} icon="dislike" />
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle1" color="primary">
                      {article.type}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="subtitle1" color="primary">
                      {article.readTime}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Box display="flex" gap="8px" alignItems="center">
                      {(article?.tags?.length > 0 || article?.industries?.length > 0) && (
                        <>
                          {camelCaseToCapital(article.tags)
                            .concat(article.industries)
                            .slice(0, 1)
                            .map((item: any, index: number) => (
                              <MyChip
                                key={index}
                                label={item}
                                backgroundColor="#FFFFFF"
                                color="#68717A"
                                border="1px solid #CED4DA"
                                maxWidth="100px"
                              />
                            ))}

                          {article.tags.length + article.industries.length > 1 && (
                            <Typography variant="caption" color="secondary">
                              +{article.tags.length + article.industries.length - 1}
                            </Typography>
                          )}
                        </>
                      )}
                    </Box>
                  </TableCell>
                  <TableCell>
                    <IconButton
                      onClick={(event) => {
                        handleClickPopover(
                          event,
                          _.get(article, 'articleId'),
                          typeof article?.accessArticle === 'boolean' ? article?.accessArticle : false,
                          article?.location
                        );
                      }}
                    >
                      <MoreVertIcon color="primary" />
                    </IconButton>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClosePopover}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                      <Box
                        className="PopoverListStyle"
                        onClick={() => {
                          handelOnClickPreview(selectedLocation);
                        }}
                      >
                        <PreviewIcon />
                        <Typography color="primary">Preview</Typography>
                      </Box>
                      {articleAccessFor ? (
                        <>
                          <Box className="PopoverListStyle" onClick={() => handelEditArticle(selectedLocation)}>
                            <EditIcon />
                            <Typography color="primary">Edit</Typography>
                          </Box>
                          <Box className="PopoverListStyle" onClick={handleOpendelete}>
                            <DeleteIcon />
                            <Typography color="primary">Delete</Typography>
                          </Box>
                        </>
                      ) : null}
                    </Popover>
                  </TableCell>
                </TableRow>
              );
            })
          ) : (
            <Box textAlign="center" p={2}>
              <Typography>No content found</Typography>
            </Box>
          )}
        </TableBody>
      );
    } else {
      return (
        <Box textAlign="center" p={2}>
          <Typography>No content found</Typography>
        </Box>
      );
    }
  };

  const compressImage = (file: any, maxWidth: any, maxHeight: any, quality: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          let { width } = img;
          let { height } = img;

          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }

          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext('2d');
          ctx?.drawImage(img, 0, 0, width, height);

          canvas.toBlob(
            (blob: any) => {
              const compressedFile = new File([blob], file.name, {
                type: file.type,
                lastModified: file.lastModified
              });

              const compressedReader = new FileReader();
              compressedReader.onload = (compressedE: any) => {
                resolve(compressedE.target.result);
              };
              compressedReader.onerror = (error) => {
                reject(error);
              };
              compressedReader.readAsDataURL(compressedFile);
            },
            file.type,
            quality
          );
        };
        img.src = e.target.result;
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  const readAsDataURL = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        resolve({ data: { link: e.target.result } });
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file);
    });
  };

  const formData = new FormData();

  const uploadArticleDescriptionFile = async (file: File) => {
    formData.append('file', file);
    try {
      const result = await API.saveArticleDescriptionFiles(formData);
      const blobUrl = _.get(_.head(_.get(result, 'data.data')), 'Bloburl', '');
      return { data: { link: blobUrl } };
    } catch {
      return Error('Failed to upload');
    }
  };

  const imageUpload = async (file: any, maxWidth: any = 400, maxHeight: any = 400, quality: any = 1.0) => {
    if (!acceptedImages.includes(file.type)) {
      return { error: 'Image upload failed' };
    } else {
      try {
        if (file.size <= 1024 * 1024) {
          const result = await uploadArticleDescriptionFile(file);
          return result;
        } else {
          const reSizedFile = await handleImageCompression(file);
          if (reSizedFile) {
            const result = await uploadArticleDescriptionFile(reSizedFile);
            return result;
          }
        }

        // formData.append("file", file)
        // const result = await API.saveArticleDescriptionFiles(formData)
        // const test =  _.get(_.head(_.get(result,'data.data')),'Bloburl','');
        // const imageDataUrl = await handleImageCompression(file)
        // const tt =  await readAsDataURL(imageDataUrl)
        // return {data: {link: test}}
        // const imageDataUrl = await compressImage(file, maxWidth, maxHeight, quality);
      } catch (error) {
        // console.error('Image upload error:', error);
        return { error: 'Image upload failed' };
      }
    }
  };

  // const imageUpload = (file: any) => {

  //   return new Promise((resolve, reject) => {
  //     const reader = new FileReader();
  //     reader.onload = (e: any) => {
  //       resolve({ data: { link: e.target.result } });
  //     };
  //     reader.onerror = (e) => {
  //       reject(e);
  //     };
  //     reader.readAsDataURL(file);
  //   });
  // };

  const handleUserTypes = (event: any, value: string[]) => {
    if (value?.length > 0) {
      if (value?.includes('All members')) {
        value = ['All members'];
      }
      setValue('articleViewableUserType', value);
      setError('articleViewableUserType', { message: undefined });
    } else {
      setValue('articleViewableUserType', []);
      setError('articleViewableUserType', { message: 'Select at least one User' });
    }
  };

  if (showLoader) {
    return <ContentSettingsLoader />;
  }

  if (groupData.error) {
    return <Box>{groupData.errorText}</Box>;
  }
  const extendedToolbarOptions = reactDraftWysiwygToolbarOptionsarticle(imageUpload, embedCallback);
  return (
    <>
      <TableContainer>
        <Table className="w-100p">
          <TableHead>
            <TableRow>
              <TableCell colSpan={7} className="py-0">
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Typography variant="h5" py={2}>
                    Content
                  </Typography>
                  <Box display="flex" justifyContent="flex-end" alignItems="center" gap="5px">
                    {/* <IconButton onClick={() => handleopenFilter(true)}>
                      <FilterIcon size={34} /> 
                    </IconButton> */}
                    <IconButton onClick={handleClickOpen}>
                      <PlusCircleIcon size={34} />
                    </IconButton>
                  </Box>
                </Box>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>CONTENT</TableCell>
              <TableCell>UPVOTES</TableCell>
              <TableCell>DOWNVOTES</TableCell>
              <TableCell>CONTENT TYPE</TableCell>
              <TableCell>READ TIME</TableCell>
              <TableCell>TAGS</TableCell>
              <TableCell>ACTION</TableCell>
            </TableRow>
          </TableHead>
          {displayArticles()}
        </Table>
      </TableContainer>

      {/* Add Edit Article Form */}
      <Dialog open={openForm} disableEnforceFocus fullWidth>
        <CommonModalHeader
          title={action === 'Edit' ? 'Edit Content' : 'Add Content'}
          handleCloseModal={handleCloseArticleForm}
        />
        <DialogContent>
          <Grid container spacing={2}>
            {/* <Grid item xs={12}>
              <Box display="flex" alignItems="center" gap="10px">
                <Switch checked={isPublished} onChange={handleSwitchSecondChange} />
                <Typography>Visible to others</Typography>
              </Box>
            </Grid> */}
            <Grid item xs={12}>
              <InputLabel>Article name *</InputLabel>
              <Controller
                name="articleName"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <TextField
                    required
                    name="articleName"
                    value={value || ''}
                    id="articleName"
                    type="text"
                    placeholder="Article Name"
                    variant="outlined"
                    style={{ width: '100%' }}
                    onChange={onChange}
                    error={checkError('articleName')}
                    helperText={getError('articleName')?.toString()}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel>Article type *</InputLabel>
              <FormControl fullWidth variant="outlined">
                <Controller
                  name="type"
                  control={control}
                  rules={{ required: true }}
                  defaultValue={type}
                  render={({ field }) => (
                    <Select
                      className="selectdrop"
                      id="articleType"
                      {...field}
                      displayEmpty
                      error={!!errors.type}
                      onChange={(event) => {
                        field.onChange(event);
                      }}
                      renderValue={(selected) => {
                        if (!selected) {
                          return <Typography color="#DCE0E4">Article type</Typography>;
                        }
                        return selected;
                      }}
                    >
                      <MenuItem value="Text">Text</MenuItem>
                      <MenuItem value="Audio">Audio</MenuItem>
                      <MenuItem value="Video">Video</MenuItem>
                    </Select>
                  )}
                />
                <FormHelperText color="error" className="text-danger">
                  {getError('type')?.toString()}
                </FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <InputLabel>Read Time *</InputLabel>
              <FormControl fullWidth variant="outlined">
                <Controller
                  name="readTime"
                  control={control}
                  defaultValue={readTimeValue}
                  render={({ field }) => (
                    <Select
                      className="selectdrop"
                      id="readTime"
                      {...field}
                      displayEmpty
                      error={!!errors.readTime}
                      renderValue={(selected) => {
                        if (!selected) {
                          // return <em>Read Time </em>;
                          return <Typography color="#DCE0E4">Read Time</Typography>;
                        }
                        return selected;
                      }}
                    >
                      <MenuItem value="1 min">1 min</MenuItem>
                      <MenuItem value="2 mins">2 mins</MenuItem>
                      <MenuItem value="3 mins">3 mins</MenuItem>
                      <MenuItem value="4 mins">4 mins</MenuItem>
                      <MenuItem value="5 mins">5 mins</MenuItem>
                      <MenuItem value="10 mins">10 mins</MenuItem>
                      <MenuItem value="15 mins">15 mins</MenuItem>
                      <MenuItem value="15+ mins">15+ mins</MenuItem>
                    </Select>
                  )}
                />
                <FormHelperText color="error" className="text-danger">
                  {getError('readTime')?.toString()}
                </FormHelperText>
              </FormControl>
            </Grid>
          </Grid>

          <Box my={2} display={'flex'} alignItems={'center'}>
            {!editCoverImg && (
              <>
                <Box
                  className="cursor-pointer"
                  marginRight="10px"
                  borderRadius="5px"
                  width="100px"
                  height="63px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  position="relative"
                  bgcolor="#EFF0F4"
                >
                  <Controller
                    name="coverImg"
                    control={control}
                    render={({ field }) => (
                      <label>
                        <input
                          id="coverImgInput"
                          name={field.name}
                          hidden
                          accept="image/*"
                          type="file"
                          onChange={(e) => {
                            if (e.target.files && e.target.files.length > 0) {
                              const file = e.target.files[0];
                              field.onChange(file);
                              handlePhotoChange(e);
                            }
                          }}
                        />
                        <CameraAltOutlinedIcon
                          className={`
                            AddContentCameraIcon
                            cursor-pointer
                            color-${!coverImageUrl && !_.head(coverImageUrl) ? 'secondary' : 'white'}`}
                        />
                        {coverImageUrl && (
                          <img
                            src={URL.createObjectURL(coverImageUrl)}
                            style={{
                              width: '100px',
                              height: '63px',
                              objectFit: 'cover',
                              borderRadius: '5px'
                            }}
                            loading="lazy"
                            alt=""
                          />
                        )}
                      </label>
                    )}
                  />
                </Box>
                <Box>
                  <Box display="flex" gap="8px" alignItems="center">
                    <Typography variant="h6">Cover image *</Typography>
                    {coverImageUrl && (
                      <Typography
                        variant="subtitle2"
                        fontSize="10px"
                        color="error"
                        onClick={handleRemoveImageFile}
                        className="cursor-pointer"
                      >
                        Remove
                      </Typography>
                    )}
                  </Box>
                  <Typography fontSize="10px" fontWeight="600" color="#999999">
                    jpg, jpeg or png (max size {imgSize.limitText})
                  </Typography>
                </Box>
              </>
            )}
            {editCoverImg && (
              <Box
                sx={{
                  width: '100px',
                  height: '63px',
                  margin: '0 20px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  border: 'none'
                }}
              >
                <img
                  src={editCoverImg}
                  style={{
                    objectFit: 'contain',
                    width: '100%',
                    height: '100%'
                  }}
                  loading="lazy"
                  alt=""
                />
              </Box>
            )}
            {editCoverImg && (
              <Box>
                {/* {typeof editCoverImg === 'string' && <p>{editCoverImg.substring(editCoverImg.lastIndexOf('/') + 1)}</p>} */}
                <Typography
                  variant="subtitle2"
                  fontSize="10px"
                  color="error"
                  onClick={handleRemoveImageFile}
                  className="cursor-pointer"
                >
                  Remove
                </Typography>
              </Box>
            )}
            {imgError && <FormHelperText error>{imgError} </FormHelperText>} {/* Display imgErrormessage */}
            {!imgError && <FormHelperText error>{getError('coverImg')?.toString()}</FormHelperText>}
          </Box>
          {watchArticleType === 'Video' && (
            <div>
              <div>
                {!videoFile && !videoUrl && (
                  <Box
                    style={{
                      background: '#FFFFFF',
                      border: '1px dashed #DEDFDF',
                      borderRadius: '8px',
                      padding: '5px 46px 15px',
                      margin: '15px 0',
                      textAlign: 'center'
                    }}
                  >
                    <input type="file" id="file" accept="video/mp4" onChange={handleFileChange} className="d-none" />
                    <input
                      type="url"
                      id="videoUrl"
                      placeholder="Enter Video URL"
                      value={defaultVideoUrl}
                      onChange={handleVideoUrlChange}
                    />
                    <IconButton>
                      <label htmlFor="file">
                        <img src={upload} alt="upload" width={'25px'} height={'25px'} />
                      </label>
                    </IconButton>
                    <Typography fontSize="12px" fontWeight="600" color="#999999">
                      <label htmlFor="file">
                        <Typography fontSize="14px" fontWeight="600" color="#3D8BFD">
                          Click to upload
                        </Typography>
                      </label>
                      {' or drag '}
                      <br />
                      Article Video file (max size 100 MB)
                    </Typography>
                  </Box>
                )}
                <Box display="flex" gap="8px" alignItems="center">
                  <Box borderRadius="8px">
                    {/* {(videoFile || videoUrl) &&
                      (videoFile?.type?.startsWith('video/') ? (
                        <video
                          style={{ borderRadius: '8px' }}
                          src={URL.createObjectURL(videoFile)}
                          width="320"
                          height="240"
                          controls
                        />
                      ) : (
                        <video style={{ borderRadius: '8px' }} src={videoUrl} width="320" height="240" controls />
                      ))} */}

                    {videoFile && videoFile instanceof Blob && (
                      <video
                        style={{ borderRadius: '8px' }}
                        src={URL.createObjectURL(videoFile)}
                        width="320"
                        height="240"
                        controls
                      />
                    )}

                    {videoUrl && (
                      <iframe
                        style={{ borderRadius: '8px', objectFit: 'cover' }}
                        src={videoUrl}
                        width="280"
                        height="180"
                      // controls
                      />
                    )}
                    {/* {editVideoUrl && (
                      <video
                        style={{ borderRadius: '8px 8px 8px 8px' }}
                        src={
                          editVideoUrl
                            ? typeof editVideoUrl === 'string'
                              ? editVideoUrl
                              : URL.createObjectURL(editVideoUrl)
                            : undefined
                        }
                        width="320"
                        height="240"
                        controls
                      />
                    )} */}
                  </Box>
                  <Box>
                    {/* {videoFile && videoFile.name ? videoFile.name : filename} */}
                    {videoFile && (
                      <Box>
                        <Box display="flex" gap="8px" alignItems="center">
                          <Typography variant="h6">Article Video Name.mp4</Typography>
                          <Typography
                            variant="subtitle2"
                            fontSize="10px"
                            color="error"
                            onClick={handleRemoveVideoFile}
                            className="cursor-pointer"
                          >
                            Remove
                          </Typography>
                        </Box>
                        <Typography fontSize="10px" fontWeight="600" color="#999999">
                          * Only Mp4 (max. 100 Mb)
                        </Typography>
                      </Box>
                    )}
                    {videoUrl && !videoFile && (
                      <Box>
                        <Box display="flex" gap="8px" alignItems="center">
                          <Typography variant="h6">Article Video Name.mp4</Typography>
                          <Typography
                            variant="subtitle2"
                            fontSize="10px"
                            color="error"
                            onClick={handleRemoveVideoFile}
                            className="cursor-pointer"
                          >
                            Remove
                          </Typography>
                        </Box>
                        <Typography fontSize="10px" fontWeight="600" color="#999999">
                          * Only Mp4 (max. 100 Mb)
                        </Typography>
                      </Box>
                    )}
                    {/* {editVideoUrl instanceof File && editVideoUrl.name && <span>{editVideoUrl.name}</span>}
                    {editVideoUrl instanceof File && !editVideoUrl.name && <span>No file name available</span>} */}
                  </Box>
                </Box>
                {videoError && <span className="text-danger">{videoError}</span>}
              </div>
            </div>
          )}
          {watchArticleType === 'Audio' && (
            <div>
              {!audioFile && !audioUrl && (
                <div
                  style={{
                    background: '#FFFFFF',
                    border: '1px dashed #DEDFDF',
                    borderRadius: '8px',
                    padding: '5px 46px 15px',
                    margin: '15px 0',
                    textAlign: 'center'
                  }}
                >
                  <input
                    type="file"
                    id="audioFile"
                    accept="audio/mp3"
                    onChange={handleAudioFileChange}
                    className="d-none"
                  />
                  <input
                    type="url"
                    id="audioUrl"
                    placeholder="Enter audio URL"
                    value={defaultAudioUrl}
                    onChange={handleAudioUrlChange}
                  />
                  <IconButton>
                    <label htmlFor="audioFile">
                      <img
                        src={upload}
                        alt="upload"
                        style={{
                          padding: 0,
                          margin: 0,
                          width: '25px',
                          height: '25px'
                        }}
                      />
                    </label>
                  </IconButton>
                  <div
                    style={{
                      fontFamily: 'Open Sans',
                      fontWeight: '600',
                      fontSize: '12px',
                      color: '#999999'
                    }}
                  >
                    <label htmlFor="audioFile">
                      <span style={{ color: '#3D8BFD', fontSize: '14px' }}>Click to upload</span>
                    </label>
                    {' or drag '}
                    <br />
                    Article Audio File (max. 100 MB)
                  </div>
                </div>
              )}
              <div style={{ display: 'flex' }}>
                <div style={{ borderRadius: '8px' }}>
                  {audioFile?.type?.startsWith('audio/') ? (
                    <audio
                      style={{ borderRadius: '8px' }}
                      src={audioFile && URL.createObjectURL(audioFile)}
                      controls
                      preload="auto"
                    >
                      Your browser does not support the audio element.
                    </audio>
                  ) : audioUrl && audioUrl.endsWith('.mp3') ? (
                    <audio style={{ borderRadius: '8px' }} src={audioUrl} controls preload="auto">
                      Your browser does not support the audio element.
                    </audio>
                  ) : null}

                  {/* {editAudioUrl && (
                    <>
                      <audio
                        style={{ borderRadius: '8px' }}
                        src={
                          editAudioUrl
                            ? typeof editAudioUrl === 'string'
                              ? editAudioUrl
                              : URL.createObjectURL(editAudioUrl)
                            : undefined
                        }
                        controls
                      />
                      <p>{editAudioUrl.name}</p>
                    </>
                  )} */}
                </div>
                {(audioFile || audioUrl) && (
                  <div
                    style={{
                      fontFamily: 'Open Sans',
                      fontWeight: '600',
                      fontSize: '14px',
                      color: '#333333',
                      marginTop: '10px',
                      margin: 'auto',
                      marginLeft: '1%'
                    }}
                  >
                    {/* <span>{watchArticleType === 'Audio' ? (audioFile.name ? audioFile.name : filename) : ''}</span> */}
                    <span className="removetext cursor-pointer" onClick={handleRemoveAudioFile}>
                      X
                    </span>
                  </div>
                )}
              </div>
              {audioError && <span className="text-danger">{audioError}</span>}
            </div>
          )}
          {watchArticleType === 'Text' && (
            <div>
              {!textFile && !textFileUrl && (
                <div
                  style={{
                    background: '#FFFFFF',
                    border: '1px dashed #DEDFDF',
                    borderRadius: '8px',
                    padding: '5px 46px 15px',
                    margin: '15px 0',
                    textAlign: 'center'
                  }}
                >
                  <input
                    type="file"
                    id="textFile"
                    accept="application/pdf,.csv"
                    className="d-none"
                    onChange={handleTextFileChange}
                  />

                  <IconButton>
                    <label htmlFor="textFile">
                      <img
                        src={upload}
                        alt="upload"
                        style={{
                          padding: 0,
                          margin: 0,
                          width: '25px',
                          height: '25px'
                        }}
                      />
                    </label>
                  </IconButton>
                  <div
                    style={{
                      fontFamily: 'Open Sans',
                      fontWeight: '600',
                      fontSize: '12px',
                      color: '#999999'
                    }}
                  >
                    <label htmlFor="textFile">
                      <span style={{ color: '#3D8BFD', fontSize: '14px' }}>Click to upload</span>
                    </label>
                    {' or drag '}
                    <br />
                    Article Text File (max. 100 MB)
                  </div>
                </div>
              )}
              <div style={{ display: 'flex' }}>
                <div style={{ borderRadius: '8px' }}>{textFileUrl && <p>{textFileUrl?.name}</p>}</div>
                {(textFile || textFileUrl) && (
                  <div
                    style={{
                      fontFamily: 'Open Sans',
                      fontWeight: '600',
                      fontSize: '14px',
                      color: '#333333',
                      marginTop: '10px',
                      marginBottom: '10px',
                      margin: 'auto',
                      marginLeft: '1%'
                    }}
                  >
                    <span
                      onClick={() =>
                        textFileUrl?.includes('http')
                          ? window.open(textFileUrl, '_blank')
                          : blobTextFileUrl
                            ? window.open(blobTextFileUrl, '_blank')
                            : null
                      }
                      style={{ color: colors.lightBlue['700'], textDecorationLine: 'underline' }}
                      className="cursor-pointer"
                    >
                      <FileOutlined /> {watchArticleType === 'Text' ? (textFile?.name ? textFile.name : filename) : ''}
                    </span>
                    <IconButton onClick={handleRemoveTextFileUrl}>
                      <Close fontSize="small" color="error" />
                    </IconButton>
                  </div>
                )}
              </div>
              {textFileError && (
                <FormHelperText color="error" className="text-danger">
                  {textFileError}
                </FormHelperText>
              )}
            </div>
          )}

          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <InputLabel>Author name *</InputLabel>
              <FormControl fullWidth>
                <Controller
                  name="authorName"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <TextField
                      required
                      name="authorName"
                      value={value || ''}
                      id="authorName"
                      type="text"
                      placeholder="Author Name"
                      variant="outlined"
                      style={{ width: '100%' }}
                      onChange={onChange}
                      error={checkError('authorName')}
                    />
                  )}
                />
                <FormHelperText color="error" className="text-danger">
                  {getError('authorName')?.toString()}
                </FormHelperText>
              </FormControl>
            </Grid>
            {/* <Grid item xs={12} md={6}>
              <InputLabel>Who can view *</InputLabel>
              <FormControl fullWidth variant="outlined">
                <Controller
                  name="accessFor"
                  control={control}
                  defaultValue={viewFor}
                  render={({ field }) => (
                    <Select
                      placeholder="Select"
                      className="selectdrop"
                      {...field}
                      error={!!errors.accessFor}
                      displayEmpty
                      renderValue={(selected) => {
                        if (!selected) {
                          return <Typography color='#DCE0E4'>Who can view</Typography>;
                        }
                        return selected;
                      }}
                    >
                      <MenuItem value="Public">Public</MenuItem>
                      <MenuItem value="Private">Private</MenuItem>
                    </Select>
                  )}
                />
                <FormHelperText color="error" className="text-danger">{getError('accessFor')?.toString()}</FormHelperText>
              </FormControl>
            </Grid> */}
            <Grid item md={6}>
              <InputLabel>Article Viewable to *</InputLabel>
              <FormControl fullWidth variant="outlined">
                <Controller
                  name="articleViewableTo"
                  control={control}
                  // defaultValue={value}
                  // value={value}

                  render={({ field }) => (
                    <Select
                      id="articleViewableTo"
                      placeholder="Select"
                      className="selectdrop"
                      {...field}
                      error={!!errors.articleViewableTo}
                      displayEmpty
                      value={getValues('articleViewableTo')}
                      renderValue={(selectedValue) => {
                        if (!selectedValue) {
                          return (
                            <span
                              style={{
                                fontFamily: 'Open Sans',
                                fontSize: '14px',
                                fontWeight: '400',
                                color: '#DCE0E4'
                              }}
                            >
                              Article viewable to
                            </span>
                          );
                        }
                        return selectedValue;
                      }}
                    >
                      <MenuItem value="All of Dosen">All of Dosen</MenuItem>
                      <MenuItem value="Organization-wide">Organization-wide</MenuItem>
                      <MenuItem value="Program only">Program only</MenuItem>
                    </Select>
                  )}
                />
                <FormHelperText error>{getError('articleViewableTo')?.toString()}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Article Accessible to *</InputLabel>
              <Controller
                name="articleViewableUserType"
                control={control}
                defaultValue={[]} // Initialize with an empty array
                render={({ field: { onChange, value } }) => (
                  <Autocomplete
                    multiple
                    options={roleOptions}
                    getOptionLabel={(option) => _.startCase(roleNameFormatter(option, mentorRoleText, menteeRoleText))}
                    value={value}
                    id="articleAccessiableTo"
                    // onError={!!errors.articleViewableTo}

                    // onChange={(_, newValue) => onChange(newValue)} // Pass the new value array
                    disableCloseOnSelect
                    getOptionDisabled={(option) =>
                      (value?.includes('All members') || value?.includes('Prefer not to say')) && option !== value[0]
                    }
                    onChange={handleUserTypes}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        error={getError('articleViewableUserType') ? true : false}
                        fullWidth
                        placeholder="Article Accessible To"
                      />
                    )}
                  />
                )}
              />
              <FormHelperText error>
                {checkError('articleViewableUserType') && getError('articleViewableUserType')?.toString()}
              </FormHelperText>
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Topics *</InputLabel>
              <FormControl fullWidth className="ethnicity-field">
                <Controller
                  name="tags"
                  control={control}
                  render={({ field }) => {
                    return (
                      <Autocomplete
                        id="topics"
                        size="small"
                        multiple // Enable selecting multiple tags
                        value={field.value || []} // Update value prop to be an array
                        onChange={(event, newValue) => {
                          setValue('tags', newValue);
                          errors['tags'] = undefined;
                        }}
                        options={topicsList.sort((a, b) => a.localeCompare(b)) || []}
                        selectOnFocus
                        clearOnBlur
                        handleHomeEndKeys
                        disableCloseOnSelect
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            placeholder="Select"
                            className="ethnicity-field"
                            error={checkError('tags')}
                            helperText={getError('tags')?.toString()}
                          />
                        )}
                      />
                    );
                  }}
                />
                {/* <Controller
                  name="tags"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      id="tags"
                      size="small"
                      multiple // Enable selecting multiple industries
                      options={topicsList || []}
                      onChange={(event, newValue) => {
                        setValue('tags', newValue);
                        errors['tags'] = undefined;
                        field.onChange(event);
                      }}
                      value={field.value || []} // Update value prop to be an array
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Select"
                          className="ethnicity-field"
                          error={checkError('industries')}
                          helperText={getError('industries')?.toString()}
                        />
                      )}
                      getOptionLabel={(option) => option}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      freeSolo
                      // error={checkError('industries')}
                    />
                  )}
                /> */}
                {/* {checkError('tags') && <FormHelperText error>{getError('tags')?.toString()}</FormHelperText>} */}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <InputLabel>Industry *</InputLabel>
              <FormControl fullWidth>
                <Controller
                  name="industries"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      id="industry"
                      size="small"
                      multiple // Enable selecting multiple industries
                      options={industryList.sort((a, b) => a.localeCompare(b)) || []}
                      // options={industryList || []}
                      onChange={(event, newValue) => {
                        newValue ? setValue('industries', newValue) : setValue('industries', '');
                        errors['industries'] = undefined;
                      }}
                      value={field.value || []} // Update value prop to be an array
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          placeholder="Select"
                          className="ethnicity-field"
                          error={checkError('industries')}
                          helperText={getError('industries')?.toString()}
                        />
                      )}
                      getOptionLabel={(option) => option}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      disableCloseOnSelect
                      freeSolo
                    // error={checkError('industries')}
                    />
                  )}
                />
                {/* <FormHelperText error>{getError('industries')?.toString()}</FormHelperText> */}
              </FormControl>
            </Grid>

            {editEmbedded && (
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <Controller
                    name="embeddedLinks"
                    control={control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <div>
                          {value ? (
                            <div className="mb-10">
                              <InputLabel> Embedded Preview * </InputLabel>
                              <iframe
                                src={value}
                                title="Embedded Preview"
                                style={{ width: '200px', height: '200px', border: 'none' }}
                              />
                              <span
                                className="removetext cursor-pointer"
                                onClick={() => {
                                  onChange('');
                                }}
                                style={{
                                  position: 'absolute',
                                  top: '0',
                                  right: '0',
                                  background: '#f8f8f8',
                                  padding: '4px',
                                  borderRadius: '50%'
                                }}
                              >
                                <CloseIcon />
                              </span>
                            </div>
                          ) : null}
                        </div>
                      );
                    }}
                  />
                </FormControl>
              </Grid>
            )}

            <Grid item xs={12}>
              <InputLabel>Article Description *</InputLabel>
              <Box className={classes.chatTextBox}>
                <Controller
                  name="articleDescription"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <>
                      <Editor
                        editorStyle={{ fontFamily: 'Open Sans' }}
                        customBlockRenderFunc={(contentBlock) => blockRender(contentBlock, 'article')}
                        customDecorators={decorator}
                        toolbar={extendedToolbarOptions}
                        editorState={editorState}
                        onEditorStateChange={handleEditorStateChange}
                        placeholder="Write Description about article"
                        toolbarClassName="toolBarStyle toolBarStyleArticle"
                        wrapperClassName="wrapperClassName p-0 b-0"
                        editorClassName="editorSize word-wrap"
                      />
                      <FormHelperText color="error" className="text-danger">
                        {checkError('articleDescription') && getError('articleDescription')?.toString()}
                      </FormHelperText>
                    </>
                  )}
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <LoadingButton fullWidth variant="contained" loading={addArticlesLoader} onClick={handleSubmit(onSubmit)}>
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>

      {/* Preview content popdialogup */}
      <PreviewContent openPreview={openPreview} setOpenPreview={setOpenPreview} singleListData={singleListData} />

      {/* Article delet dialog */}
      <ArticleDeletDialog
        openDelete={openDelete}
        setDelete={setDelete}
        deleteLoader={deleteLoader}
        handleDelete={handelDeleteArticle}
      />

      {/* Filter dialog design */}
      {/* <ContentFilter
        loading={showLoader}
        openFilter={openFilter}
        handleopenFilter={handleopenFilter}
      /> */}
    </>
  );
};
export default ContentSettings;
