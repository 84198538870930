/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from 'react';
import _ from 'lodash';
import { Box, Popover, TableRow, TableCell, IconButton, Typography } from '@mui/material';
import {
  ClockIcon,
  AnswerIcon,
  DeleteIcon,
  PreviewIcon
  // ProfilePersonIcon
} from '../../../../../../utils/Icons';
import { toast } from 'react-toastify';
import { API } from '../../../../../../api';
import BlockIcon from '@mui/icons-material/Block';
import Upvotes from '../../../../components/Upvotes';
import ProgramDeletDialog from '../ProgramDeletDialog';
import { useQueryClient } from '@tanstack/react-query';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { useNavigate, useParams } from 'react-router-dom';
import RoleTypeTag from '../../../../components/RoleTypeTag';
import ChatAvatarComponent from '../../../chat/ChatAvatarComponent';
import LevelQuestionAnswers from '../../Dialogs/LevelQuestionAnswers';
import { useAppDispatch, useAppSelector } from '../../../../../../redux/hooks';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import { acsTokenActions } from '../../../../../../redux/slices/acs-token/acsTokenSlice';
import LinearProgressWithLabel from '../../../Matches/components/LinearProgressWithLabel';
import { acsChannelActions } from '../../../../../../redux/slices/acs-channels/acsChannels';
import { IGroupUser, groupUsersActions } from '../../../../../../redux/slices/group-users/groupUsersSlice';
import {
  GroupBasedRoleFormatter,
  formatNumberWithCommas,
  roleNameFormatter,
  unixToHumanReadable
} from '../../../../components/common';
import { communityUsersActions } from '../../../../../../redux/slices/acs-community-members/acsCommunityMembers';
import { IAllTaskData } from '../../../../../../redux/slices/goals-tasks/getTasksSlice';
// import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
// import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
// import LinearProgress, {
//   linearProgressClasses,
// } from "@mui/material/LinearProgress";
// import ViewMatchesPopup from './ViewMatchesPopup';

interface Props {
  data: IGroupUser;
  closedUserId: any;
  setShowProfile: any;
  setActiveUserId: any;
  scrollToUserCall: any;
  setScrollToUserCall: any;
  showGoalProgress: boolean;
  handleOpenTasks: any;
  showCompany?: boolean;
  // anchorAction: any;
  // setAnchorAction: any;
}
const ProgramUserTableRow = ({
  data,
  setActiveUserId,
  setShowProfile,
  scrollToUserCall,
  showGoalProgress,
  setScrollToUserCall,
  closedUserId,
  handleOpenTasks,
  showCompany
}: // anchorAction,
// setAnchorAction,
Props) => {
  const grpId = useParams().id || '';
  const [openDelete, setDelete] = useState(false);
  const [openActive, setActiveDialog] = useState(false);
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [anchorAction, setAnchorAction] = React.useState<HTMLButtonElement | null>(null);
  const handleViewAnswer = (flag: boolean) => setViewAnswerOpen(flag);
  const [viewAnswerOpen, setViewAnswerOpen] = useState(false);
  const mentorRoleText = GroupBasedRoleFormatter('mentor', 'Mentor');
  const menteeRoleText = GroupBasedRoleFormatter('mentee', 'Mentee');
  const activeItemRef = useRef<any>(null);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  // const [isViewMatchOpen, setViewMatchOpen] = useState(false);
  // const handleViewMatchOpen = (flag: boolean) => setViewMatchOpen(flag);
  // useEffect(() => {
  //   activeItemRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
  // }, []);

  const scrollToUser = () => {
    if (activeItemRef.current) {
      activeItemRef?.current?.scrollIntoView({ block: 'center' });
    }
  };

  useEffect(() => {
    if (scrollToUserCall) {
      scrollToUser();
      setScrollToUserCall(false);
    }
  }, [scrollToUserCall === true]);

  // let roleColor = '#000';
  // if (data.role.length === 1) {
  //   if (data.role[0] === 'admin') {
  //     roleColor = '#28A745';
  //   } else if (data.role[0] === 'mentee') {
  //     roleColor = '#0071A9';
  //   } else {
  //     roleColor = '#E99940';
  //   }
  // }

  const dispatch = useAppDispatch();
  const usersLoginDetails = useAppSelector((state) => state.groupUsers.data?.users);

  const handleClickAction = (event: any) => {
    setAnchorAction(event.currentTarget);
  };

  const handleCloseAction = () => {
    setAnchorAction(null);
  };
  const companyName = data.company?.name;
  const companyLocation = data?.company?.location;

  // const handleDeleteOption = (e: any) => {
  //   setDelete(true);
  //   setAnchorAction(e.currentTarget);
  // };

  const removeUser = async () => {
    setAnchorAction(null);

    setDeleteLoader(true);
    try {
      const response = await API.removeUserFromGroup({
        grpId,
        userId: data.userId
      });
      if (response.status) {
        toast.success('User removed successfully');
        dispatch(groupUsersActions.removeUser({ userId: data.userId }));
        setDeleteLoader(true);
        setDelete(true);
        handleCloseAction();
      }
    } catch (e) {
      setDeleteLoader(false);
      setAnchorAction(null);
      toast.error('User failed to be removed, please try again');
    }
  };

  const activateUser = async () => {
    try {
      setDeleteLoader(true);
      const response = await API.activateUser({
        isActive: data.isActive ? false : true,
        grpId,
        userId: data.userId
      });
      if (response.status === 200) {
        toast.success(`User ${data.isActive ? 'deactivated' : 'reactivated'} successfully `);
        setActiveDialog(false);
        setDeleteLoader(false);
        dispatch(groupUsersActions.updateUser({ ...data, isActive: data.isActive ? false : true }));
        setAnchorAction(null);
      }
    } catch (e) {
      setDeleteLoader(false);
      toast.error(_.get(e, 'response.data.message', 'Something went wrong, please try again'));
    }
  };
  const [taskLoading, setTasksLoading] = useState(false);
  const [tasksData, setTasksData] = useState<IAllTaskData | null>(null);
  const open = Boolean(anchorAction);
  const openAction = Boolean(anchorAction);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      <TableRow>
        <TableCell colSpan={3}>
          <Box
            display="flex"
            alignItems="center"
            // onClick={() => {
            //   setActiveUserId(data.userId);
            //   setShowProfile(true);
            // }}
          >
            <Box data-user-id={data.userId} ref={data.userId === closedUserId ? activeItemRef : null}>
              <Box>
                <ChatAvatarComponent
                  image={_.get(data, 'headshot', '')}
                  type="noStatus"
                  firstLetter={data.name.charAt(0)}
                  width="40px"
                  height="40px"
                  cursor={'pointer'}
                  onClickOnUserIcon={() => {
                    setActiveUserId(data.userId);
                    setShowProfile(true);
                  }}
                />
              </Box>
            </Box>

            <Box
              marginLeft={'8px'}
              className="cursor-pointer"
              onClick={() => {
                setActiveUserId(data.userId);
                setShowProfile(true);
              }}
            >
              <Typography variant="h5">{_.get(data, 'name', '')}</Typography>
              <Typography>{data.category}</Typography>
            </Box>
          </Box>
        </TableCell>

        <TableCell colSpan={3}>
          <Box display="flex" gap="4px">
            {data.role.map((role) => {
              return (
                <React.Fragment key={role}>
                  <RoleTypeTag
                    category={_.startCase(roleNameFormatter(role, mentorRoleText, menteeRoleText))}
                    place="userTable"
                  />
                </React.Fragment>
              );
            })}
          </Box>
        </TableCell>

        {showCompany && (
          <TableCell colSpan={3} title={companyName && companyName}>
            <Typography variant="subtitle1" color="primary">
              {companyName ? (companyName.length > 14 ? companyName.slice(0, 12) + '...' : companyName) : ''}
            </Typography>
          </TableCell>
        )}

        {showCompany && (
          <TableCell colSpan={2} title={companyLocation}>
            <Typography variant="subtitle1" color="primary">
              {companyLocation
                ? companyLocation.length > 14
                  ? companyLocation.slice(0, 12) + '...'
                  : companyLocation
                : ''}
            </Typography>
          </TableCell>
        )}

        <TableCell colSpan={3}>
          {usersLoginDetails
            ?.filter((user: any) => user.userId === data.userId)
            .map((user) => (
              <Typography variant="subtitle1" color="primary" key={user.userId}>
                {unixToHumanReadable(user.lastLogin)}
              </Typography>
            ))}
        </TableCell>

        <TableCell>
          <Typography variant="subtitle1" color="primary">
            {data?.onboardStatus === 'live' ? 'Adopted' : _.startCase(data?.onboardStatus)}
          </Typography>
        </TableCell>

        <TableCell>
          <Typography variant="subtitle1" color="primary">
            {formatNumberWithCommas(data?.sentMessages)}
          </Typography>
        </TableCell>

        {/* Goal progress bar */}
        {showGoalProgress && (
          <TableCell colSpan={3}>
            <Box width="100px">
              {data?.role.includes('mentee') ? (
                <LinearProgressWithLabel value={data?.goalProgress !== undefined ? data.goalProgress : 0} />
              ) : (
                '—'
              )}
            </Box>
          </TableCell>
        )}

        <TableCell colSpan={3}>
          {usersLoginDetails
            ?.filter((user: any) => user.userId === data.userId)
            .map((user) => (
              <React.Fragment key={user.userId}>
                {user?.xpPoints >= 0 ? (
                  <Upvotes likes={user.xpPoints} icon="xpPoint" />
                ) : (
                  <Upvotes likes={0} icon="xpPoint" />
                )}
              </React.Fragment>
            ))}
        </TableCell>

        <TableCell>
          <Typography variant="subtitle1" color="primary">
            {data.meetings} {/* Displaying meetings number */}
          </Typography>
        </TableCell>
        
        {/* <TableCell>
          <img src={smileimg} alt="smileimg" width="24px" height="24px" />
        </TableCell> */}

        {/* <TableCell>152</TableCell> */}
        {/* <TableCell>65</TableCell> */}

        <TableCell colSpan={3} align="center">
          <IconButton onClick={handleClickAction}>
            <MoreVertIcon color="primary" />
          </IconButton>
          {/* Actions popover code */}
          <Popover
            id={id}
            open={openAction}
            anchorEl={anchorAction}
            onClose={handleCloseAction}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left'
            }}
            transformOrigin={{
              vertical: 'center',
              horizontal: 'right'
            }}
          >
            {/* View Member */}
            <Box
              className="PopoverListStyle"
              onClick={() => {
                setActiveUserId(data.userId);
                setShowProfile(true);
              }}
            >
              <PreviewIcon />
              <Typography color="primary">View member</Typography>
            </Box>
            {/* View Matches */}
            {/* <Box
              className="PopoverListStyle"
              onClick={() => handleViewMatchOpen(true)}
            >
              <ProfilePersonIcon />
              <Typography color="primary">View Matches</Typography>
            </Box> */}
            {/* Remove Member */}
            <Box
              className="PopoverListStyle"
              onClick={() => {
                setDelete(true);
              }}
            >
              <DeleteIcon />
              <Typography color="primary">Remove member</Typography>
            </Box>
            {/* Chat History */}
            <Box
              className="PopoverListStyle"
              onClick={() => {
                localStorage.setItem('user', JSON.stringify(data));
                localStorage.setItem('chatAccessed', JSON.stringify(true));
                localStorage.removeItem('acsToken');
                dispatch(acsTokenActions.updateAcsToken(0));
                dispatch(
                  acsChannelActions.atnSaveChannels2({
                    groupThreads: [],
                    personalThreadsArr: []
                  })
                );
                dispatch(communityUsersActions.update(0));

                navigate('/app/chat', { replace: true });
              }}
            >
              <ClockIcon />
              <Typography color="primary">Chat history</Typography>
            </Box>
            {/* Deactivate & Reactivate Member */}
            <Box
              className="PopoverListStyle"
              onClick={() => {
                setActiveDialog(true);
              }}
            >
              {data.isActive ? <BlockIcon fontSize="small" /> : <NotificationsOutlinedIcon fontSize="small" />}
              <Typography color="primary">{data.isActive ? 'Deactivate' : 'Reactivate'} member</Typography>
            </Box>

            {/* View plan progress */}
            {data.role.includes('mentee') && (
              <>
                <Box className="PopoverListStyle" onClick={() => handleOpenTasks(data.userId)}>
                  <PreviewIcon />
                  <Typography color="primary">View plan progress</Typography>
                </Box>
                {/* <Box className="PopoverListStyle" onClick={() => handleOpenTasks(data.userId)}>
                  <AnswerIcon />
                  <Typography color="primary">View answers</Typography>
                </Box> */}
              </>
            )}
            {/* View answers */}
          </Popover>
        </TableCell>
      </TableRow>

      {/* Program delete popup */}
      <ProgramDeletDialog
        openDelete={openDelete}
        setDelete={setDelete}
        deleteLoader={deleteLoader}
        text="Are you sure you want to remove the member from this group?"
        handleDelete={removeUser}
      />

      {/* Program set Active popup */}
      <ProgramDeletDialog
        openDelete={openActive}
        setDelete={setActiveDialog}
        deleteLoader={deleteLoader}
        text={`Are you sure you want to ${data.isActive ? 'deactivate' : 'reactivate'} the member from this group?`}
        handleDelete={activateUser}
        btnText={data.isActive ? 'Deactivate' : 'Reactivate'}
      />

      {/* View matches popup */}
      {/* <ViewMatchesPopup
        open={isViewMatchOpen}
        onClose={() => handleViewMatchOpen(false)}
      /> */}
    </>
  );
};

export default ProgramUserTableRow;
